@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 0px 0px 10px 0px #0000001f;
  border-radius: 10px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
}

.priceValue {
  color: #000000;
  font-size: 20px;
  line-height: 1.1;
  letter-spacing: -1px;
  font-weight: 700;
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.title {
  margin: 0;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
}

.mainInfo {
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.card {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  height: 100%;
}

.aside {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  align-items: flex-end;
}

.meta {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.metaItem {
  display: flex;
  gap: 6px;
  opacity: 0.5;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.29;

  svg {
    width: 18px;
    display: block;
  }

  > div {
    width: calc(100% - 24px);
  }
}

.status {
  line-height: 1.38;
}

.avatar {
  width: 44px;
  height: 44px;
}
